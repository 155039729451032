require("./bootstrap");
// require('../../public/js/jquery.min.js');
// require('../../public/js/jquery.js');
// require('../../public/js/owl.js');

window.Vue = require("vue");
import Vue from "vue";

import router from "./site/routes";
import Vuex from "vuex";
import store from "./site/store";
import vueDebounce from "vue-debounce";

Vue.component("site", require("./site/components/SiteComponent.vue").default);
Vue.component(
    "slider",
    require("./site/components/SliderComponent.vue").default
);
Vue.component("card", require("./site/components/CardComponent.vue").default);
Vue.component("list", require("./site/components/ListComponent.vue").default);
Vue.component(
    "sidebar",
    require("./site/components/SidebarComponent.vue").default
);
Vue.component("loanding", require("./site/components/LoandingImg.vue").default);

Vue.use(Vuex);
Vue.use(vueDebounce);
const app = new Vue({
    el: "#site",
    router: router,
    store: store,
    data() {
        return {
            user: {},
            axiosSource: [],
        };
    },
    created() {},
    mounted() {},
    methods: {
        trans($texto) {
            //definir una forma de traduccion, se sugiere cargar un array con todas las traducciones, un solo archivo para cada idioma/
            return $texto;
        },

        async Consulta(options) {
            if (
                typeof this.axiosSource[options.noDuplicate] == "undefined" &&
                typeof options.noDuplicate != "undefined"
            ) {
                this.axiosSource[options.noDuplicate] =
                    axios.CancelToken.source();
            } else if (typeof options.noDuplicate != "undefined") {
                this.axiosSource[options.noDuplicate].cancel();
                this.axiosSource[options.noDuplicate] =
                    axios.CancelToken.source();
            }

            if (typeof options != "object") {
                return;
            }

            if (typeof options.responseType == "undefined") {
                options.responseType = "json";
            }

            if (typeof options.data == "undefined") {
                options.data = {};
            }

            let resultado = "";

            const config = {
                headers: {
                    // 'Content-Type': `multipart/form-data;`,
                },
                responseType: options.responseType,
                cancelToken:
                    typeof this.axiosSource[options.noDuplicate] != "undefined"
                        ? this.axiosSource[options.noDuplicate].token
                        : "",
            };

            return await axios
                .post(options.url, options.data, config)
                .then((response) => {
                    resultado = response.data;
                    this.axiosSource.splice(options.noDuplicate, 1);
                    return resultado;
                })
                .catch((error) => {
                    this.axiosSource.splice(options.noDuplicate, 1);

                    if (error.response && error.response.status === 422) {
                        resultado = error.response.data;
                    } else {
                        resultado =
                            "Ha ocurrido un error inesperado. Intente de nuevo más tarde.";
                    }

                    return resultado;
                });
            return resultado;
        },
    },
});
