<template data-rsssl="1" class="slider">
    <div id="aa-wp" :class="mobileOn">

        <header class="Header">
            <div class="cont">
                <div class="top dfx alg-cr jst-sb">
                    <figure class="logo">
                        <router-link :to="'/'">
                            <img src="/img/logo.png?2" alt="logo">
                        </router-link>
                    </figure>
                    <span :class="mobileOn+' MenuBtn aa-tgl'" data-tgl="aa-wp"  @click="mobileToggle"><i></i><i></i><i></i></span>
                    <span :class="mobileOn+' MenuBtnClose aa-tgl'" data-tgl="aa-wp" @click="mobileToggle"></span>
                    <div class="Rght BgA dfxc alg-cr fg1">
                        <div class="Search">
                            <form method="get" action="/search" id="searchform" @submit.prevent="search(this)">
                                <span class="Form-Icon">
                                    <input v-model="searchValue" name="s" type="search" placeholder="Buscar libros..." autocomplete="off">
                                    <button id="searchsubmit" type="submit"><i class="fa-search"></i></button>
                                </span>
                            </form>
                        </div>

                        <nav class="Menu fg1">
                            <ul>
                                <li class="menu-item menu-item-type-custom menu-item-object-custom">
                                   <router-link :to="{name:'Index'}">Inicio</router-link>
                                </li>
                                <li class="menu-item menu-item-type-custom menu-item-object-custom">
                                    <router-link :to="{name:'Nosotros'}">¿Quiénes Somos? </router-link>
                                </li>
                                <li id="menu-item-doble" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-doble">
                                    <a href="#">Categorias</a>
                                    <ul class="sub-menu">
                                        <li id="" class="menu-item menu-item-type-taxonomy menu-item-object-category" v-for="(category,key) in categories" v-bind:key="key">
                                            <router-link :to="{name:'CategoryName',params:{slug:category.slug,name:category.name}}">{{category.name}}</router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li id="menu-item-doble" class="menu-item menu-item-type-custom menu-item-object-custom">
                                    <router-link :to="{name:'Authors'}">Autores</router-link>
                                </li>

                                <li class="menu-item menu-item-type-custom menu-item-object-custom">
                                    <router-link :to="{name:'Contacto'}">Contacto</router-link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>

		<router-view>

		</router-view>


        <footer class="ft ok">
            <div class="cont dfxb alg-cr">
                <figure class="logo-ft"><img src="/img/logo_black.png" alt="cuevana"></figure>
                <nav class="dfxb jst-sb fg1 alg-cr">
                    <ul class="mnft dfx jst-cr">
                        <li id="menu-item-1994" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1994">
							<a href="#">
								Aviso legal
							</a>
						</li>
                        <li id="menu-item-1995" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1995">
							<a href="#">
								DMCA
							</a>
						</li>

                    </ul>
                    <ul class="smft dfx jst-cr">
                        <li><a href="https://www.facebook.com/" class="fa-facebook-f fab"></a></li>
                        <li><a href="https://twitter.com/" class="fa-twitter fab"></a></li>
                    </ul>
                </nav>
            </div>
            <p class="copy tac">© 2022 ShebaLibros, Todos los derechos reservados.</p>
        </footer>
    </div>
</template>

<script type="text/javascript">

	export default {
        props:[
            "categories",
            "authors",
            "sidebar",
            "pagination",
            "cards",
            "name",
            "slug",
            "sections",
            "slider",
            "card",
            "authors_all",
		],
		data(){
			return {
                searchValue:'',
                mobileOn:'',

			}
        },
		computed: {


        },
        methods:{
            search(){
                this.$router.push({name:'BooksSearch',query:{
                    q:this.searchValue
                }});

            },
            mobileToggle(){
                if(this.mobileOn == 'on'){
                    this.mobileOn='';
                }else{
                    this.mobileOn='on';
                }
            }

        },
        created(){
            this.$store.commit('sidebar',this.sidebar);
            this.$store.commit('pagination',this.pagination);
            this.$store.commit('cards',this.cards);
            this.$store.commit('name',this.name);
            this.$store.commit('slug',this.slug);
            this.$store.commit('sections',this.sections);
            this.$store.commit('slider',this.slider);
            this.$store.commit('card',this.card);
            this.$store.commit('authors_all',this.authors_all);

            // $(window).scroll(function () {
            //     if ($(this).scrollTop() > 150) {
            //         $('.Header').addClass('fx');
            //     } else {
            //         $('.Header').removeClass('fx');
            //     }
            // });

        },
        mounted(){
            // $('.aa-tgl').on('click', function () {
            //     var shwhdd = $(this).attr('data-tgl');
            //     console.log(shwhdd);
            //     $('#' + shwhdd).toggleClass('on');
            //     $(this).toggleClass('on');
            // });
        },
        watch:{
            $route (newValue) {
                if(this.mobileOn == 'on'){
                    this.mobileOn='';
                }
            },
        }
	}
</script>
