<template>
    <div class="bd">
      <div class="TpRwCont cont">
        <main :class="overlay">
          <section class="home-series">
            <div class="Top center-flex">
              <h2 class="Title">¿Quiénes Somos?</h2>
            </div>

            <div class="content-wrapper">
              <p>La Biblioteca Sheba es una recopilación no exhaustiva de los libros más relevantes del repertorio adventista, que circulan gratuitamente por Internet y las redes sociales. Nuestro objetivo no es competir con las editoriales oficiales. Por esa razón, hemos procurado no incluir los libros que actualmente están siendo vendidos en las casas editoriales oficiales. </p>

              <p>La biblioteca será alimentada semanalmente con nuevas obras. Actualmente la plataforma ya reúne más de 500 obras divididas en más de 50 categorías, es hasta la fecha el mayor repertorio virtual adventista hispanohablante del mundo. Aquí encontrará libros en inglés; inéditos; que nunca antes han sido traducidos o muy antiguos que ya no se encuentran en las editoriales. Acceso gratuito a la descarga en formato PDF y/o ePub.</p>

              <p>Esta Biblioteca nace como un proyecto de evangelización, con el propósito de dar privilegio a las personas que no tienen los recursos necesarios o la localización ideal para adquirir un buen material de estudio para su crecimiento espiritual. De la misma forma los libros presentados fueron cuidadosamente seleccionado para estar enfocados en una única visión de verdad presente. </p>

              <p class="team-signature">El equipo Sheba</p>

              <p>Bienaventurado el que lee… Y …El que lee, entienda.</p>
              <p>—Apocalipsis 1:3 ; — Mateo 24:15</p>
            </div>
          </section>
        </main>
        <sidebar></sidebar>
      </div>
    </div>
  </template>

  <script type="text/javascript">
  export default {
    data() {
      return {
        overlay: "",
        authors: {},
      };
    },
    mounted() {},
    created() {

    },
    methods: {
      getAuthors() {

      },
    },
  };
  </script>

  <style scoped>
  .content-wrapper {
    color: #fff;
    text-align: justify;
    line-height: 35px;
    width: 100%;
  }

  .team-signature {
    text-align: right;
    font-style: italic;
  }
  </style>
