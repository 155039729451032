<template>
    <div>  
        <aside class="widget-area">
            <div v-for="(item,key) in sitebar" :key="key" id="peli_top_estrenos-2" class="Wdgt peli_top_estrenos">
                <div class="Title widget-title">{{item.key}}</div>
                <!-- <ul class="MovieList top"> -->
                <ul class="MovieList">
                    <li v-for="(card,kCard) in item.cards" :key="kCard">
                        <div class="TPost A">
                            <router-link 
                                :to="{name:'BooksName',params:{slug:card.slug,card:card}}"    
                            >
                                <div class="Image">
                                    <figure class="Objf TpMvPlay AAIco-play_arrow">
                                        <img
                                            class="lazy"
                                            :src="card.image"
                                            :data-src="card.image"
                                            :alt="card.slug"
                                        />
                                    </figure>
                                </div>
                                <div class="Title">{{card.name}}</div>
                            </router-link>
                            <p class="Info">
                                <span class="Vote AAIco-star">{{card.stars}}</span> 
                                <span class="Time AAIco-access_time">Autor: {{card.authors}}</span> 
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </aside>
    </div>
</template>       
<script type="text/javascript">
    export default{
        props:{
            
        },
        data(){
            return {
                sitebar:[]
                
            }
        },
        methods:{
            
        },
        created(){
            this.sitebar=this.$store.state.sidebar;
        }
    }
</script>