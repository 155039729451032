<template>
    <div class="bd">
        <div class="TpRwCont cont">
            <main :class="overlay">
                <section class="home-series">
                    <div class="Top center-flex">
                        <h2 class="Title">Donaciones</h2>
                    </div>

                    <div>
                        <div class="">
                            <p
                                style="
                                    text-align: justify;
                                    line-height: 35px;
                                    width: 100%;
                                "
                            >
                                Si desea contribuir a nuestras acciones y al
                                desarrollo de la biblioteca Sheba ayudándonos de
                                manera puntual o regular, tiene la posibilidad
                                de hacer una donación decidiendo usted mismo la
                                cantidad. Las donaciones servirán sobre todo
                                para desarrollar la página teniendo cada vez más
                                contenido de calidad, en la edición y la
                                traducción de las obras, pero también para los
                                futuros proyectos que deseamos poner en marcha.
                            </p>
                        </div>
                        <form
                            action="https://www.paypal.com/donate"
                            method="post"
                            target="_top"
                        >
                            <input
                                type="hidden"
                                name="hosted_button_id"
                                value="3XZMDJJPENJ96"
                            />
                            <input
                                type="image"
                                src="/img/paypal.webp"
                                border="0"
                                style="
                                    width: 320px;
                                    background: transparent;
                                    border: 0px;
                                    margin: 0 auto;
                                    margin-top: 50px;
                                "
                                name="submit"
                                title="PayPal - The safer, easier way to pay online!"
                                alt="Donate with PayPal button"
                                width="200px"
                            />
                            <img
                                alt=""
                                border="0"
                                src="/img/paypal.webp"
                                width="1"
                                height="1"
                            />
                        </form>
                    </div>
                </section>
            </main>
            <sidebar></sidebar>
        </div>
    </div>
</template>
<script type="text/javascript">
export default {
    data() {
        return {
            overlay: "",
            authors: {},
        };
    },
    mounted() {},
    created() {},
    methods: {
        getAuthors() {},
    },
};
</script>
