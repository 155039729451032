<template>
    <div class="bd">
        <div class="TpRwCont cont">
            <main :class="overlay">
                <section class="home-series">
                    <div class="Top center-flex">
                        <h2 class="Title">Contacto</h2>
                    </div>

                    <h2 style="color: #fff; margin-bottom: 20px">
                        ¡Nos alegra que quieras contactarnos!
                    </h2>
                    <p style="font-size: 16px; line-height: 1.6; color: #fff">
                        Estamos aquí para servirte y responder cualquier
                        inquietud que tengas. Al igual que en Mateo 18:20 donde
                        dice: "Porque donde están dos o tres congregados en mi
                        nombre, allí estoy yo en medio de ellos", creemos en el
                        poder de la comunidad y en fortalecer nuestra conexión
                        contigo.
                    </p>
                    <p style="color: #fff">
                        Por favor, déjanos tu mensaje y haremos todo lo posible
                        por responder a la brevedad. Juntos, podemos seguir
                        creciendo en fe y entendimiento.
                    </p>
                    <p style="margin-top: 30px; color: #fff">
                        Que Dios te bendiga abundantemente,<br />El equipo de
                        Sheba
                    </p>

                    <p v-if="backendErrors.nombre" class="error-message">
                        {{ backendErrors.nombre[0] }}
                    </p>
                    <p v-if="backendErrors.email" class="error-message">
                        {{ backendErrors.email[0] }}
                    </p>
                    <p v-if="backendErrors.mensaje" class="error-message">
                        {{ backendErrors.mensaje[0] }}
                    </p>

                    <div v-if="isSending" class="sending-overlay">
                        Enviando...
                    </div>

                    <div v-else>
                        <div class="mdl-bd" v-if="ocultar === false">
                            <form
                                id="form-register-user"
                                @submit.prevent="SubmitForm"
                            >
                                <p>
                                    <input
                                        required=""
                                        type="text"
                                        placeholder="Nombre"
                                        v-model="mail.nombre"
                                    />
                                </p>
                                <p class="mb1">
                                    <input
                                        required=""
                                        type="email"
                                        placeholder="Email"
                                        v-model="mail.email"
                                    />
                                </p>
                                <p class="mb1">
                                    <textarea
                                        required=""
                                        type="password"
                                        placeholder="Mensaje"
                                        v-model="mail.mensaje"
                                    >
                                    </textarea>
                                </p>
                                <p>
                                    <button
                                        class="Button btn-block"
                                        type="submit"
                                        :disabled="isSending"
                                    >
                                        Enviar
                                    </button>
                                </p>
                            </form>
                        </div>
                    </div>
                    <div v-html="mensaje"></div>
                    <div>
                        <div class="">
                            <h2 style="color: #fff; margin-bottom: 20px">
                                Donaciones
                            </h2>

                            <p
                                style="
                                    text-align: justify;
                                    line-height: 35px;
                                    width: 100%;
                                    color: #fff;
                                "
                            >
                                Si desea contribuir a nuestras acciones y al
                                desarrollo de la biblioteca Sheba ayudándonos de
                                manera puntual o regular, tiene la posibilidad
                                de hacer una donación decidiendo usted mismo la
                                cantidad. Las donaciones servirán sobre todo
                                para desarrollar la página teniendo cada vez más
                                contenido de calidad, en la edición y la
                                traducción de las obras, pero también para los
                                futuros proyectos que deseamos poner en marcha.
                            </p>
                        </div>
                        <form
                            action="https://www.paypal.com/donate"
                            method="post"
                            target="_top"
                        >
                            <input
                                type="hidden"
                                name="hosted_button_id"
                                value="3XZMDJJPENJ96"
                            />
                            <input
                                type="image"
                                src="/img/paypal.webp"
                                border="0"
                                style="
                                    width: 320px;
                                    background: transparent;
                                    border: 0px;
                                    margin: 0 auto;
                                    margin-top: 50px;
                                "
                                name="submit"
                                title="PayPal - The safer, easier way to pay online!"
                                alt="Donate with PayPal button"
                                width="200px"
                            />
                            <img
                                alt=""
                                border="0"
                                src="/img/paypal.webp"
                                width="1"
                                height="1"
                            />
                        </form>
                    </div>
                </section>
            </main>
            <sidebar></sidebar>
        </div>
    </div>
</template>

<script type="text/javascript">
export default {
    data() {
        return {
            ocultar: false,
            mensaje: "",
            overlay: "",
            isSending: false,
            mail: {
                nombre: null,
                email: null,
                mensaje: null,
            },
            backendErrors: {},
        };
    },
    methods: {
        SubmitForm() {
            this.isSending = true;
            this.backendErrors = {};

            let url = "mail/submit";
            const options = {
                url: url,
                noDuplicate: "form-register-user",
                data: this.mail,
            };

            this.$root.Consulta(options).then((response) => {
                this.isSending = false;

                if (response.error === false) {
                    this.mail = {
                        nombre: null,
                        email: null,
                        mensaje: null,
                    };

                    this.ocultar = true;
                } else if (response.errors) {
                    this.backendErrors = response.errors;
                }

                this.mensaje = response.message;
            });
        },
    },
};
</script>

<style scoped>
.sending-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2em;
    z-index: 1000;
}
.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}
.success-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(245, 245, 245, 0.9); /* Ligero fondo gris */
}

.inner {
    text-align: center;
    max-width: 600px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff; /* Fondo blanco */
}

.success-icon {
    width: 80px;
    height: auto;
    margin-bottom: 20px;
}

h2 {
    color: #2e8b57; /* Color verde */
    font-weight: bold;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
}
</style>
