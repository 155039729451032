<template>
    <div class="bd">
        <div class="TpRwCont cont">
            <main :class="overlay">
                <section class="home-series">
                    <div class="Top center-flex">
                        <h2 class="Title">Donaciones</h2>
                    </div>

                    <div
                        style="
                            text-align: center;
                            padding: 20px;
                            border: 1px solid #dcdcdc;
                            border-radius: 10px;
                            background-color: #141a32;
                            max-width: 600px;
                            margin: 0 auto;
                        "
                    >
                        <img
                            src="/img/logo_black.png"
                            alt="Logo de tu organización"
                            style="max-width: 150px; margin-bottom: 15px"
                        />
                        <h2 style="color: #ddd; margin-bottom: 20px">
                            ¡Gracias por tu generosidad!
                        </h2>
                        <p
                            style="
                                color: #ddd;
                                font-size: 16px;
                                line-height: 1.6;
                            "
                        >
                            Tus acciones reflejan el amor de Cristo y nos ayudan
                            a continuar con la misión de compartir el mensaje
                            adventista con más personas. Tu donación es una
                            bendición y será usada en la obra de extender el
                            Reino de Dios.
                        </p>
                        <p
                            style="
                                color: #ddd;
                                font-size: 16px;
                                line-height: 1.6;
                            "
                        >
                            "Cada uno dé como propuso en su corazón, no con
                            tristeza ni por obligación; porque Dios ama al dador
                            alegre." - <strong>2 Corintios 9:7</strong>
                        </p>
                        <p style="color: #ddd; margin-top: 30px">
                            Con amor y gratitud,<br />El equipo de Sheba
                        </p>
                    </div>
                </section>
            </main>
            <sidebar></sidebar>
        </div>
    </div>
</template>
<script type="text/javascript">
export default {
    data() {
        return {
            overlay: "",
            authors: {},
        };
    },
    mounted() {},
    created() {},
    methods: {
        getAuthors() {},
    },
};
</script>
