import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    routes:[
        {
			path:'/',
			name:'Index',
			component : require('./views/Index').default,
		},
        {
			path:'/biblioteca-general/:page?',
			name:'biblioteca',
			component : require('./views/BooksList').default,
		},
        {
			path:'/contacto',
			name:'Contacto',
			component : require('./views/Contacto').default,
		},
        {
			path:'/donaciones',
			name:'Donaciones',
			component : require('./views/Donaciones').default,
		},
        {
			path:'/donacion/completada',
			name:'Donaciones',
			component : require('./views/DonacionesCompletada').default,
		},
        {
			path:'/donacion/cancelada',
			name:'Donaciones',
			component : require('./views/Donaciones').default,
		},
        {
			path:'/nosotros',
			name:'Nosotros',
			component : require('./views/Nosotros').default,
		},
        {
			path:'/categories',
			name:'Category',
			component : require('./views/BooksList').default,
			children:[
				{
					path:':slug/:page?',
					name:'CategoryName',
					component : require('./views/BooksList').default,
					props: true
				}
			]
		},
        {
			path:'/author',
			name:'Authors',
			component : require('./views/Authors').default,
		},
        {
			path:'/author/:slug/:page?',
			name:'AuthorName',
			component : require('./views/BooksList').default,
			props: true
		},
        {
			path:'/books',
			name:'Books',
			component : require('./views/Book').default,
			children:[
				{
					path:':slug',
					name:'BooksName',
					component : require('./views/Book').default,
					props: true
				}
			]
		},
        {
			path:'/search/:page?',
			name:'BooksSearch',
			component : require('./views/BooksList').default
		},
    ],
	mode: 'history',
	scrollBehavior() {
		return {x:0, y:0}
	}


})
