var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type_list === 'list')?_c('div',{staticClass:"apt",attrs:{"id":"tab-1"}},[_c('ul',{staticClass:"MovieList Rows AX A06 B04 C03 E20"},_vm._l((_vm.cards_list),function(card,key){return _c('li',{key:key,staticClass:"xxx TPostMv"},[_c('card',{attrs:{"card":card}})],1)}),0),_vm._v(" "),((typeof _vm.pagination_component != 'undefined' && _vm.pagination_component))?_c('nav',{staticClass:"navigation pagination"},[_c('div',{staticClass:"nav-links"},[(_vm.pagination.current_page == 1)?_c('a',{staticClass:"prev page-numbers",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"fa-arrow-left"})]):_c('router-link',{staticClass:"prev page-numbers",attrs:{"to":{
                        name:_vm.$route.name,
                        params:{
                            slug:_vm.$route.params.slug,
                            name:_vm.name,
                            page:(_vm.pagination.current_page - 1)
                        },
                        query:{
                            q:_vm.$route.query.q
                        }
                    }}},[_c('i',{staticClass:"fa-arrow-left"})]),_vm._v(" "),_vm._l((_vm.pagesNumber),function(page){return _c('router-link',{key:page,class:[page == _vm.pagination.current_page? 'page-link current':'page-link' ],attrs:{"to":{
                        name:_vm.$route.name,
                        params:{
                            slug:_vm.$route.params.slug,
                            name:_vm.name,
                            page:page
                        },
                        query:{
                            q:_vm.$route.query.q
                        }
                    }}},[_vm._v("\n                    "+_vm._s(page)+"\n                ")])}),_vm._v(" "),_c('span',[_vm._v("de")]),_vm._v(" "),_c('router-link',{staticClass:"page-link last",attrs:{"to":{
                        name:_vm.$route.name,
                        params:{
                            slug:_vm.$route.params.slug,
                            name:_vm.name,
                            page:_vm.pagination.last_page
                        },
                        query:{
                            q:_vm.$route.query.q
                        }
                    }}},[_vm._v("    \n                    "+_vm._s(_vm.pagination.last_page)+"\n                ")]),_vm._v(" "),(_vm.pagination.current_page == _vm.pagination.last_page)?_c('a',{staticClass:"next page-numbers",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"fa-arrow-right"})]):_c('router-link',{staticClass:"next page-numbers",attrs:{"to":{
                        name:_vm.$route.name,
                        params:{
                            slug:_vm.$route.params.slug,
                            name:_vm.name,
                            page:(_vm.pagination.current_page + 1)
                        },
                        query:{
                            q:_vm.$route.query.q
                        }
                    }}},[_c('i',{staticClass:"fa-arrow-right"})])],2)]):_vm._e()]):(_vm.type_list === 'car')?_c('div',{staticClass:"books-sheba"},[_c('div',{staticClass:"owl-carousel owl-theme tvshows-owl owl-loaded owl-drag"},[_c('div',{staticClass:"owl-stage-outer"},[_c('div',{staticClass:"owl-stage"},_vm._l((_vm.cards_list),function(card,key){return _c('div',{key:key,class:_vm.section_type+' card owl-item',on:{"mouseover":_vm.activeCard}},[_c('card',{attrs:{"card":card}})],1)}),0)])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }