<template>
  <div class="bd">
    <div class="TpRwCont cont">
      <main>
        <article class="TPost movtv-info cont">
          <div class="Image">
            <figure>
              <img
                :src="card.image"
                :data-src="card.image"
                :alt="card.name"
                class="lazy"
              />
            </figure>
            <center>
              <star-rating
                v-model="rating"
                @input="SubmitVote"
                class=""
              ></star-rating><br>
              <small>{{card.votes}} Votos</small>
              <div class="TPost A" style="padding-left:0px;">
                <p class="Info">
                  <span class="Vote AAIco-star" style="margin-right: 0px;">{{ card.stars }}</span>
                </p>
              </div>
            </center>
          </div>
          <header>
            <h1 class="Title">{{ card.name }}</h1>
          </header>
          <div class="Description" v-html="card.description"></div>
          <div class="MvTbCn on anmt" id="MvTb-Info">
            <ul class="InfoList">
              <li class="AAIco-adjust">
                <strong>Categorias:</strong>
                <span class="color-w">{{ card.categories }}</span>
              </li>
              <li class="AAIco-adjust loadactor">
                <strong>Autores:</strong>
                <span class="color-w">{{ card.authors }}</span>
              </li>
              <li class="AAIco-adjust">
                <strong>Tags:</strong>
                <span class="color-w">{{ card.tags }}</span>
              </li>
            </ul>
          </div>
        </article>
        <div class="Wdgt peli_top_estrenos">
          <div class="Title widget-title">Descargas</div>

          <div class="Wdgt" v-for="(link, key) in download" :key="key">
            <div class="Title widget-title">FORMATO {{ key }}</div>
            <ol>
              <li v-for="(dow, Lkey) in link" :key="Lkey">
                <a :href="dow.url_download" target="_blank"
                  >Google Drive: {{ dow.name }}</a
                >
              </li>
            </ol>
          </div>
        </div>
      </main>

      <sidebar></sidebar>
    </div>
  </div>
</template>
<script type="text/javascript">
import StarRating from "./../components/StarRating.vue";

export default {
  data() {
    return {
      card: {},
      download: {},
      rating: 0,
      selectedRating: 0,
    };
  },
  components: {
    StarRating,
  },
  mounted() {},
  created() {
    if (this.$store.state.card !== false) {
      this.card = this.$store.state.card;
      this.$store.commit("card", false);
    } else if (typeof this.$route.params.card != "undefined") {
      this.card = this.$route.params.card;
    } else {
      this.getBook();
    }
    this.getDownload();
    this.rating = this.card.stars;
  },
  methods: {
    getBook() {
      this.download={};

      const options = {
        url: this.$route.path,
      };

      this.$root.Consulta(options).then((response) => {
        if (response != false) {
          this.card = response;
        }
      });
    },
    getDownload() {
      this.download={};

      const options = {
        url: this.$route.path + "/links",
      };

      this.$root.Consulta(options).then((response) => {
        if (response != false) {
          this.download = response;
        }
      });
    },
    SubmitVote() {
      const options = {
        url: this.$route.path + "/rate",
        data: {
          rate: this.rating,
        },
      };

      this.$root.Consulta(options).then((response) => {
        this.card.votes=response.book.votes;
        this.rating=response.book.rating;
        this.card.stars=response.book.rating;

      });

    },
  },
  watch: {
    "$route.params.card"(newValue) {
      if (typeof newValue != "undefined") {
        this.card = this.$route.params.card;
        this.download={};
        this.getDownload();
      }
    },
  },
  computed: {},
};
</script>
