<template>
  <div class="star-rating">
    <span
      v-for="(star, index) in stars"
      :key="index"
      class="star"
      :class="starClasses(index)"
      @mouseover="hoverStar(index)"
      @click="setRating(index)"
      @mouseleave="resetRating"
    >
      ★
    </span>
  </div>
</template>

  <script>
export default {
  name: "StarRating",
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stars: 5,
      selectedStar: 0,
    };
  },
  mounted() {
    this.selectedStar = this.value;
  },
  methods: {
    starClasses(index) {
      return {
        "is-active": index < this.selectedStar,
      };
    },
    hoverStar(index) {
      this.selectedStar = index + 1;
    },
    setRating(index) {
      this.selectedStar = index + 1;
      this.$emit("input", this.selectedStar);
    },
    resetRating() {
      this.selectedStar = this.value;
    },
  },
};
</script>

  <style>
.star-rating {
  display: inline-block;
  font-size: 0;
  white-space: nowrap;
  cursor: pointer;
}
.star-rating .star {
  display: inline-block;
  font-size: 2rem;
  color: #ccc;
  transition: color 0.2s ease-out;
}
.star-rating .star.is-active,
.star-rating .star:hover {
  color: #f7d352;
}
</style>
