import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authors_all : false,
    slug        : false,
    name        : false,
    cards       : false,
    card        : false,
    pagination  : false,
    
    sections    : false,
    slider      : false,

    card        : false,
    sidebar     : [],
  },
  mutations: {
    authors_all (state,authors_all = false) {
      state.authors_all=authors_all;
    },
    card (state,card = false) {
      state.card=card;
    },
    name (state,name = false) {
      state.name=name;
    },
    sections (state,sections = false) {
      state.sections=sections;
    },
    slider (state,slider = false) {
      state.slider=slider;
    },
    slug (state,slug = false) {
        state.slug=slug;
    },
    cards (state,cards = false) {
      state.cards=cards;
    },
    card (state,card = false) {
      state.card=card;
    },
    pagination (state,pagination = false) {
      state.pagination=pagination;
    },
    sidebar (state,sidebar = []) {
      state.sidebar=sidebar;
    }
  }
})