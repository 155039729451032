<template>
    <div class="bd">

        <slider
            :slider="slider"
        ></slider>

        <div class="TpRwCont cont">
            <main>
                <section v-for="(section,key) in sections" :key="key">
                    <div class="Top" style="text-align: center;">
                        <h2 class="Title" >{{section.key}}</h2>

                    </div>
                    <list
                        :type="section.type"
                        :cards="section.cards"
                    >
                    </list>
                    <router-link
                        v-if="section.type_view == 'lasted'"
                        :to="{name:'biblioteca',params:{name:'Biblioteca General'}}"
                        class="Button loadmore"
                    >
                        Cargas mas libros
                    </router-link>

                </section>

            </main>
            <sidebar></sidebar>

        </div>
    </div>
</template>
<script type="text/javascript">
    export default{
        props:{

        },
        data(){
            return {
                sections:[],
                slider:[]
            }
        },
        methods:{
            getSections(){
                const options = {
                    url:this.$route.path
                };

                this.$root.Consulta(options).then(response=>{
                    if(response != false){
                        this.sections=response.sections;
                        this.slider=response.slider;
					}
                });
            }
        },
        created(){
            if(this.$store.state.sections !== false){
                this.sections       = this.$store.state.sections;
                this.slider         = this.$store.state.slider;
            }else{
                this.getSections();
            }
        }
    }
</script>
