<template>
    <div class="TPost C post-7544 post type-post status-publish format-standard has-post-thumbnail hentry">
        <router-link  
            :to="{name:'BooksName',params:{slug:card_read.slug,card:card_read}}"    
        >
            <div class="Image">
                <figure class="Objf TpMvPlay AAIco-play_arrow">
                    <img width="160"
                        height="242"
                        :src="card_read.image"
                        :data-src="card_read.image"
                        class="lazy attachment-thumbnail size-thumbnail wp-post-image"
                        alt="img"
                    >
                </figure>
            </div>
            <h2 class="Title">{{card_read.name}}</h2>
        </router-link >
        <div class="TPMvCn anmt">
            <div class="Title">{{card_read.name}}</div>
            <p class="Info"> 
                <span class="Vote AAIco-star">{{card_read.stars}}</span> 
            </p>
            <div class="Description">
                <p></p>
                <p>
                    {{card_read.short_description}}
                </p>
                <p></p>
                <p class="Director AAIco-videocam">
                    <span>Autor:</span> 
                    {{card_read.authors}}
                </p>
                <p class="Genre AAIco-movie_creation">
                    <span>Categorias:</span> 
                    {{card_read.categories}}
                </p>
                <p class="Actors AAIco-person">
                    <span>Tags:</span> 
                    {{card_read.tags}}
                </p>
            </div> 
                <img 
                    width="160" 
                    height="242"
                    :data-src="card_read.image"
                    class="lazy attachment-thumbnail size-thumbnail wp-post-image bg"
                    alt="img"
                    :src="card_read.image"

                >
        </div>
    </div>


</template>

<script type="text/javascript">
    export default {
        props:[
            'card'
        ],
        data(){
            return {
                card_test:{
                    name        : 'El Camino a Cristo',
                    short_description : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
                    image       : '/images/books/27/profetas-y-reyes.jpeg',
                    stars       : 3,
                    categories  : 'Salvacion, Test, Religion',
                    tags        : 'Test1, Test, Tags',
                    authors     : 'Elena G. de White, Test1, Authors',
                    link        : '#',
                },
                card_read:{}
            }
        },
        created(){
            if(typeof this.card != "undefined"){
                this.card_read=this.card;
            }else{
                this.card_read=this.card_test;
            }
        },
        watch:{
            card(){
                this.card_read=this.card;
            }
        }
        
    }
</script>