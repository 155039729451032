var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bd"},[_c('div',{staticClass:"TpRwCont cont"},[_c('main',{class:_vm.overlay},[_c('section',{staticClass:"home-series"},[_c('div',{staticClass:"Top center-flex"},[_c('h2',{staticClass:"Title"},[_vm._v(_vm._s(_vm.name))])]),_vm._v(" "),(_vm.search)?_c('center',[_c('loanding',{attrs:{"width":300,"height":300}})],1):_vm._e(),_vm._v(" "),(!_vm.search && _vm.cards.length === 0)?_c('h3',[_vm._v("No hay resultados en la busqueda.")]):_vm._e(),_vm._v(" "),(!_vm.search)?_c('list',{attrs:{"cards":_vm.cards,"overlay":_vm.overlay}}):_vm._e()],1),_vm._v(" "),_c('nav',{staticClass:"navigation pagination"},[_c('div',{staticClass:"nav-links"},[(_vm.pagination.current_page == 1)?_c('a',{staticClass:"prev page-numbers",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"fa-arrow-left"})]):_c('router-link',{staticClass:"prev page-numbers",attrs:{"to":{
                            name:_vm.$route.name,
                            params:{
                                slug:_vm.$route.params.slug,
                                name:_vm.name,
                                page:(_vm.pagination.current_page - 1)
                            },
                            query:{
                                q:_vm.$route.query.q
                            }
                        }}},[_c('i',{staticClass:"fa-arrow-left"})]),_vm._v(" "),_vm._l((_vm.pagesNumber),function(page){return _c('router-link',{key:page,class:[page == _vm.pagination.current_page? 'page-link current':'page-link' ],attrs:{"to":{
                            name:_vm.$route.name,
                            params:{
                                slug:_vm.$route.params.slug,
                                name:_vm.name,
                                page:page
                            },
                            query:{
                                q:_vm.$route.query.q
                            }
                        }}},[_vm._v("\n                        "+_vm._s(page)+"\n                    ")])}),_vm._v(" "),_c('span',[_vm._v("de")]),_vm._v(" "),_c('router-link',{staticClass:"page-link last",attrs:{"to":{
                            name:_vm.$route.name,
                            params:{
                                slug:_vm.$route.params.slug,
                                name:_vm.name,
                                page:_vm.pagination.last_page
                            },
                            query:{
                                q:_vm.$route.query.q
                            }
                        }}},[_vm._v("    \n                        "+_vm._s(_vm.pagination.last_page)+"\n                    ")]),_vm._v(" "),(_vm.pagination.current_page == _vm.pagination.last_page)?_c('a',{staticClass:"next page-numbers",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('i',{staticClass:"fa-arrow-right"})]):_c('router-link',{staticClass:"next page-numbers",attrs:{"to":{
                            name:_vm.$route.name,
                            params:{
                                slug:_vm.$route.params.slug,
                                name:_vm.name,
                                page:(_vm.pagination.current_page + 1)
                            },
                            query:{
                                q:_vm.$route.query.q
                            }
                        }}},[_c('i',{staticClass:"fa-arrow-right"})])],2)])]),_vm._v(" "),_c('sidebar')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }