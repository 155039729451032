<template>
    <div class="bd">    
        <div class="TpRwCont cont">
            <main :class="overlay">
                <section class="home-series">
                    <div class="Top center-flex">
                        <h2 class="Title">Autores</h2>
                        <!-- <div class="btnstp"> <a data-tab="tabserie-1" href="javascript:void(0)"
                                class="Button STPb Current">ÚLTIMAS</a> <a data-tab="tabserie-2"
                                href="javascript:void(0)" class="Button STPb ho-naranja">ESTRENOS</a> <a
                                data-tab="tabserie-3" href="javascript:void(0)"
                                class="Button STPb ho-naranja">RANKING</a> <a data-tab="tabserie-4"
                                href="javascript:void(0)" class="Button STPb ho-naranja">MAS VISTAS</a></div> <a
                            href="https://cuevana3.me/serie" class="Button sm"
                            style="width:130px;margin-left:15px;flex:none;">Ver mas series</a> -->
                    </div>

                    <div>
                        <div class="list-authors" v-for="(author,key) in authors" :key="key">
                            <h3 class="key-authors">{{key}}</h3>
                            <div class="author-item" v-for="(auth,key) in author" :key="key">
                                <router-link :to="{name:'AuthorName',params:{slug:auth.slug,name:auth.name}}">{{auth.name}}</router-link>
                            </div>
                        </div>
                    </div>
                </section>
                
            </main>
            <sidebar></sidebar>

        </div>
    </div>
</template>       
<script type="text/javascript">
    export default{
        data(){
            return {
                overlay:'',
                authors:{}
            }
        },
        mounted(){
            
        },
        created(){
            console.log(this.$store.state.authors_all);
            if(this.$store.state.authors_all !== false){
                this.authors       = this.$store.state.authors_all;

                this.$store.commit('authors_all',false);
                console.log('paso');
                
            }else{
                console.log('manda a cargar');
                this.getAuthors();
            }
           
        },
        methods:{
            getAuthors(){
                this.overlay="overlay";
                
                let url = this.$route.path;
                const options = {
                    url:url
                };

                this.$root.Consulta(options).then(response=>{
                    if(response != false){
                        this.authors=response.authors_all;
					}
                    this.overlay="";
                });
            }
        }
    }
</script>