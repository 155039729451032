<template>
    <div class="MovieListSldCn">
        <div class="MovieListSld owl-carousel owl-theme owl-loaded owl-drag">
            <div class="owl-stage-outer">
                <div class="owl-stage">
                    <div class="owl-item" v-for="(page,key) in pages" v-bind:key="key">
                        <div class="TPostMv item">
                            <div class="TPost D" style="height: 350px;">
                                <a>
                                    <div class="Image">
                                        <figure class="Objf">
                                            <img
                                                id="cpt"
                                                class=" lazy"
                                                alt="img"
                                                :src="page.image"
                                                :data-src="page.image"
                                            >
                                        </figure>
                                    </div>
                                </a>
                                <div class="TPMvCn">

                                    <p >
                                        <br>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
export default ({
    props: [
        "slider"
    ],
    data(){
        return {
            pages:[],
            test:[
                {
                    name:"Camino a Cristo",
                    image:"https://cdn.pixabay.com/photo/2022/03/10/15/48/flower-7060129_960_720.jpg",
                    slug:"libro-de-algo",
                    stars:"3",
                    description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid quos distinctio perferendis velit explicabo ad labore, sint ipsum quas sequi debitis fugiat laboriosam, beatae culpa accusantium voluptas soluta et dolore.",
                    authors:["libro","otro"]
                },
                {
                    name:"Segundo Slider",
                    image:"https://cdn.pixabay.com/photo/2014/01/22/19/44/flower-field-250016_960_720.jpg",
                    slug:"libro-de-algo",
                    stars:"4",
                    description:"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid quos distinctio perferendis velit explicabo ad labore, sint ipsum quas sequi debitis fugiat laboriosam, beatae culpa accusantium voluptas soluta et dolore.",
                    categories:["libro","otro"]
                },
            ]
        }
    },
    created(){
        if(typeof(this.slider) == "object"){
            this.pages=this.slider;
        }else{
            this.pages=this.test;
        }


        const n=this;
        $(document).ready(function() {
            $('.MovieListSld').owlCarousel({
                loop: true,
                nav: false,
                lazyLoad: true,
                items: 1,
                autoplay: true,
                autoplayTimeout: 12000,
            });
        })

    },
    methods:{

    },
    watch:{
        slider(){
            this.pages=this.slider;
            $(document).ready(function() {
                $('.MovieListSld').owlCarousel({
                    loop: true,
                    nav: false,
                    lazyLoad: true,
                    items: 1,
                    autoplay: true,
                    autoplayTimeout: 4000,
                });
            })
        },

    }
})
</script>
>
