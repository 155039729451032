<template>
    <div>
        <div id="tab-1" class="apt" v-if="type_list === 'list'">
            <ul class="MovieList Rows AX A06 B04 C03 E20">
                <li class="xxx TPostMv" v-for="(card,key) in cards_list" v-bind:key="key">
                    <card
                        :card="card"
                    ></card>
                </li>
            </ul>
            <nav class="navigation pagination" v-if="(typeof pagination_component != 'undefined' && pagination_component)">
                <div class="nav-links"> 
                    
                    <a v-if="pagination.current_page == 1" href="#" @click.prevent="" class="prev page-numbers">    
                        <i class="fa-arrow-left"></i>
                    </a> 
                    <router-link 
                        v-else  
                        :to="{
                            name:$route.name,
                            params:{
                                slug:$route.params.slug,
                                name:name,
                                page:(pagination.current_page - 1)
                            },
                            query:{
                                q:$route.query.q
                            }
                        }" 
                        class="prev page-numbers"
                    >    
                        <i class="fa-arrow-left"></i>
                    </router-link> 
                    
                        
                    <router-link 
                        v-for="page in pagesNumber" 
                        v-bind:key="page"  
                        :to="{
                            name:$route.name,
                            params:{
                                slug:$route.params.slug,
                                name:name,
                                page:page
                            },
                            query:{
                                q:$route.query.q
                            }
                        }"  
                        v-bind:class="[page == pagination.current_page? 'page-link current':'page-link' ]"
                    >
                        {{page}}
                    </router-link> 
                    
                    
                    <span>de</span> 
                    
                    <router-link 
                        :to="{
                            name:$route.name,
                            params:{
                                slug:$route.params.slug,
                                name:name,
                                page:pagination.last_page
                            },
                            query:{
                                q:$route.query.q
                            }
                        }" 
                        class="page-link last"
                    >    
                        {{pagination.last_page}}
                    </router-link> 

                    
                    <a v-if="pagination.current_page == pagination.last_page" href="#" @click.prevent="" class="next page-numbers">    
                        <i class="fa-arrow-right"></i>
                    </a> 

                    <router-link 
                        v-else 
                        :to="{
                            name:$route.name,
                            params:{
                                slug:$route.params.slug,
                                name:name,
                                page:(pagination.current_page + 1)
                            },
                            query:{
                                q:$route.query.q
                            }
                        }" 
                        class="next page-numbers"
                    >    
                        <i class="fa-arrow-right"></i>
                    </router-link> 

                </div>
            </nav>
            
        </div>
        
        <div class="books-sheba" v-else-if="type_list === 'car'">
            <div class="owl-carousel owl-theme tvshows-owl owl-loaded owl-drag">
                <div class="owl-stage-outer">
                    <div class="owl-stage">
                        <div :class="section_type+' card owl-item'" @mouseover="activeCard" v-for="(card,key) in cards_list" v-bind:key="key">
                            <card
                                :card="card"
                            ></card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>       
<script type="text/javascript">
    export default{
        props:[
            "type",
            "cards",
            "url",
            "pagination_component"
        ],
        data(){
            return {
                section_type:(Math.random() + 1).toString(36).substring(7),
                type_list:'list',
                offset: 10,
                search: false,
                overlay: false,
                pagination: {
                    'total': 0,
                    'current_page': 0,
                    'per_page': 0,
                    'last_page': 0,
                    'from': 0,
                    'to': 0
                },
                cards_list:[
                    {

                    },
                    {

                    },
                    {

                    },
                    {

                    },
                    {

                    },
                ],
               
            }
        },
        
        created(){
            
            if(typeof this.cards != "undefined"){
                this.cards_list = this.cards;
            }

            if(typeof this.type != "undefined"){
                this.type_list = this.type;
            }
            
            const n=this;
            $(document).ready(function() {
                $('.tvshows-owl').owlCarousel({
    
                    loop: false,
                    margin: 0,
                    nav: false,
                    responsiveClass: true,
                    responsive: {
                        0: {
                            items: 1
                        },
                        425: {
                            items: 2
                        },
                        576: {
                            items: 3
                        },
                        768: {
                            items: 2
                        },
                        992: {
                            items: 3
                        },
                        1200: {
                            items: 4
                        }
                    }
                });
            })
        },
        
        mounted(){
            
        },
        methods:{
            activeCard(event){
                let card = $('.'+this.section_type+'.card.active');
                for (let index = 0; index < card.length; index++) {
                    if(index > 1){
                        $(card[index]).addClass('last-active-owl');
                    }else{
                        $(card[index]).removeClass('last-active-owl');
                    }
                }
            },
            getCategory(){
                this.overlay="overlay";
                
                let url = (typeof this.$route.query.q == "undefined")?this.$route.path+"/"+this.url:this.$route.path+"/"+this.url+"?q="+this.$route.query.q;
                
                const options = {
                    url:url,
                    noDuplicate:'BooksList'
                };

                this.search     = true;

                this.$root.Consulta(options).then(response=>{
                    if(response != false){
                        // this.name=response.result.name;
                        this.cards=response.result.cards;
                        this.search     = false;
                        this.pagination = response.pagination;
                        this.overlay="";
					}
                });
            }
        },
        watch:{
            cards (newValue) { 
                if(typeof this.cards != "undefined"){
                    this.cards_list = this.cards;
                }
            },
            "$route.params.name" (newValue) { 
                if(typeof newValue != "undefined"){
                    this.getCategory();
                    this.name=this.$route.params.name;
                } 
            },
            "$route.params.page" (newValue) { 
                if(typeof newValue != "undefined"){
                    this.getCategory();
                    this.name=this.$route.params.name;
                } 
            },
            "$route.query.q" (newValue) {
                if(typeof newValue != "undefined"){
                    this.getCategory();
                    this.name='Buscar: '+newValue;
                } 
            },

        },
        computed: {
            isActived: function() {
                return this.pagination.current_page;
            },
            pagesNumber: function() {
                if(!this.pagination.to){
                    return [];
                }

                var from = this.pagination.current_page - this.offset; 
                
                if(from < 1){
                    from = 1;
                }

                var to = from + (this.offset * 2); 
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }

                if(to > (this.pagination.current_page+3)){
                    if(this.pagination.current_page < 4){
                        to = 7;
                    }else{
                        to = (this.pagination.current_page+3);

                    }
                }

                if(from < (this.pagination.current_page-3)){
                    from = (this.pagination.current_page-3);
                }

                var pagesArray = [];
                while(from <= to){
                    pagesArray.push(from);
                    from++;
                }

                return pagesArray;
            }
        },
    }
</script>