<template>
    <div class="bd">    
        <div class="TpRwCont cont">
            <main :class="overlay">
                <section class="home-series">
                    <div class="Top center-flex">
                        <h2 class="Title">{{name}}</h2>
                        <!-- <div class="btnstp"> <a data-tab="tabserie-1" href="javascript:void(0)"
                                class="Button STPb Current">ÚLTIMAS</a> <a data-tab="tabserie-2"
                                href="javascript:void(0)" class="Button STPb ho-naranja">ESTRENOS</a> <a
                                data-tab="tabserie-3" href="javascript:void(0)"
                                class="Button STPb ho-naranja">RANKING</a> <a data-tab="tabserie-4"
                                href="javascript:void(0)" class="Button STPb ho-naranja">MAS VISTAS</a></div> <a
                            href="https://cuevana3.me/serie" class="Button sm"
                            style="width:130px;margin-left:15px;flex:none;">Ver mas series</a> -->
                    </div>
                    <center v-if="search">
                        <loanding 
                            :width="300"
                            :height="300"
                        ></loanding>
                    </center>
                    <h3 v-if="!search && cards.length === 0">No hay resultados en la busqueda.</h3>
                    <list
                        v-if="!search"
                        :cards="cards"
                        :overlay="overlay"
                    ></list>
                </section>
                <nav class="navigation pagination">
                    <div class="nav-links"> 
                        
                        <a v-if="pagination.current_page == 1" href="#" @click.prevent="" class="prev page-numbers">    
                            <i class="fa-arrow-left"></i>
                        </a> 
                        <router-link 
                            v-else  
                            :to="{
                                name:$route.name,
                                params:{
                                    slug:$route.params.slug,
                                    name:name,
                                    page:(pagination.current_page - 1)
                                },
                                query:{
                                    q:$route.query.q
                                }
                            }" 
                            class="prev page-numbers"
                        >    
                            <i class="fa-arrow-left"></i>
                        </router-link> 
                        
                            
                        <router-link 
                            v-for="page in pagesNumber" 
                            v-bind:key="page"  
                            :to="{
                                name:$route.name,
                                params:{
                                    slug:$route.params.slug,
                                    name:name,
                                    page:page
                                },
                                query:{
                                    q:$route.query.q
                                }
                            }"  
                            v-bind:class="[page == pagination.current_page? 'page-link current':'page-link' ]"
                        >
                            {{page}}
                        </router-link> 
                        
                        
                        <span>de</span> 
                        
                        <router-link 
                            :to="{
                                name:$route.name,
                                params:{
                                    slug:$route.params.slug,
                                    name:name,
                                    page:pagination.last_page
                                },
                                query:{
                                    q:$route.query.q
                                }
                            }" 
                            class="page-link last"
                        >    
                            {{pagination.last_page}}
                        </router-link> 

                        
                        <a v-if="pagination.current_page == pagination.last_page" href="#" @click.prevent="" class="next page-numbers">    
                            <i class="fa-arrow-right"></i>
                        </a> 

                        <router-link 
                            v-else 
                            :to="{
                                name:$route.name,
                                params:{
                                    slug:$route.params.slug,
                                    name:name,
                                    page:(pagination.current_page + 1)
                                },
                                query:{
                                    q:$route.query.q
                                }
                            }" 
                            class="next page-numbers"
                        >    
                            <i class="fa-arrow-right"></i>
                        </router-link> 

                    </div>
                </nav>
            </main>
            <sidebar></sidebar>

        </div>
    </div>
</template>       
<script type="text/javascript">
    export default{
        data(){
            return {
                overlay:'',
                name:'',
                search:true,
                cards:{},
                offset: 10,
                pagination: {
                    'total': 0,
                    'current_page': 0,
                    'per_page': 0,
                    'last_page': 0,
                    'from': 0,
                    'to': 0
                },
            }
        },
        mounted(){
            
        },
        created(){
            if(typeof this.$route.params.name != "undefined"){
                this.name=this.$route.params.name;
            }

            if(this.$store.state.pagination !== false){
                this.name       = this.$store.state.name;
                this.cards      = this.$store.state.cards;
                this.pagination = this.$store.state.pagination;

                this.search     = false;

                this.$store.commit('name',false);
                this.$store.commit('cards',false);
                this.$store.commit('pagination',false);
                
            }else{
                this.getCategory();
            }
           
        },
        methods:{
            getCategory(){
                this.overlay="overlay";
                
                let url = (typeof this.$route.query.q == "undefined")?this.$route.path:this.$route.path+"?q="+this.$route.query.q;
                const options = {
                    url:url,
                    noDuplicate:'BooksList'
                };

                this.search     = true;

                this.$root.Consulta(options).then(response=>{
                    if(response != false){
                        this.name=response.result.name;
                        this.cards=response.result.cards;
                        this.search     = false;
                        this.pagination = response.pagination;
                        this.overlay="";
					}
                });
            }
        },
        watch:{
            "$route.params.name" (newValue) { 
                if(typeof newValue != "undefined"){
                    this.getCategory();
                    this.name=this.$route.params.name;
                } 
            },
            "$route.params.page" (newValue) { 
                if(typeof newValue != "undefined"){
                    this.getCategory();
                    this.name=this.$route.params.name;
                } 
            },
            "$route.query.q" (newValue) {
                if(typeof newValue != "undefined"){
                    this.getCategory();
                    this.name='Buscar: '+newValue;
                } 
            },

        },
        computed: {
            isActived: function() {
                return this.pagination.current_page;
            },
            pagesNumber: function() {
                if(!this.pagination.to){
                    return [];
                }

                var from = this.pagination.current_page - this.offset; 
                
                if(from < 1){
                    from = 1;
                }

                var to = from + (this.offset * 2); 
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }

                if(to > (this.pagination.current_page+3)){
                    if(this.pagination.current_page < 4){
                        to = 7;
                    }else{
                        to = (this.pagination.current_page+3);

                    }
                }

                if(from < (this.pagination.current_page-3)){
                    from = (this.pagination.current_page-3);
                }

                var pagesArray = [];
                while(from <= to){
                    pagesArray.push(from);
                    from++;
                }

                return pagesArray;
            }
        },
    }
</script>